/*
 * @Description: app mutations type
 */

export enum AppMutationTypes {
  TOGGLE_DEVICE = 'TOGGLE_DEVICE',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_CONFIG = 'SET_CONFIG',
  SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST',
  SET_LIGHT = 'SET_LIGHT'
}
