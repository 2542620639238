import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/Index.vue')
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
        name: 'Home'
      },
      {
        path: 'game',
        component: () => import(/* webpackChunkName: "game" */ '@/views/game/Game.vue'),
        name: 'game'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "list" */ '@/views/list/List.vue'),
        name: 'list'
      },
      {
        path: 'hotDetail',
        component: () => import(/* webpackChunkName: "hotDetail" */ '@/views/hot_detail/HotDetail.vue'),
        name: 'hotDetail'
      },
      {
        path: 'carouselDetail',
        component: () => import(/* webpackChunkName: "carouselDetail" */ '@/views/carousel_detail/CarouselDetail.vue'),
        name: 'carouselDetail'
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
  scrollBehavior() {
    return { left: 0, top: 0 }
  }
})

router.beforeEach(() => {
  window.document.body.scrollTop = 0
  window.document.documentElement.scrollTop = 0
})

export function resetRouter() {
  const newRouter = router;
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
