const deviceDetect: () => 'Mobile'|'Desktop' = () => {
  const ua: boolean = /Mobi|Android|iPhone/i.test(navigator.userAgent)
  const touch: boolean = 'ontouchstart' in document.documentElement

  if (ua && touch) return 'Mobile'
  else return 'Desktop'
}

export {
  deviceDetect
}
