
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup() {
    const route = useRoute()
    const key = () => {
      return route.path
    }
    return {
      key
    }
  }
})
