
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const dataMap = reactive({
      year: new Date().getFullYear()
    })
    const isGame = computed(() => useRoute().path !== '/game')

    return {
      t,
      isGame,
      ...toRefs(dataMap)
    }
  }
})
