/*
 * @Description: app state
 */
import { getLocale } from '@/locales'
import { deviceDetect } from '@/utils/deviceDetect'

export enum DeviceType {
  Mobile = 1,
  Desktop
}

export interface Config {
  contactUs: {
    email: string
    skype: string
    whatapp: string
  }
  resource: string
  webUrl: string
}

export interface AppState {
  device: DeviceType
  config: Config
  language: string
  languageList: object[]
  light: boolean
}

export const state: AppState = {
  device: DeviceType[deviceDetect()],
  config: {
    contactUs: {
      email: '',
      skype: '',
      whatapp: ''
    },
    resource: '',
    webUrl: ''
  },
  language: getLocale(),
  languageList: [],
  light: false
}
