import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isGame)
    ? (_openBlock(), _createElementBlock("footer", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.t('home.copyright', {year: new Date().getFullYear()})), 1)
      ]))
    : _createCommentVNode("", true)
}