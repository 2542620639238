
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { getPerson, getLanguage } from '@/apis/app'
import { useStore } from '@/store'
import { AppActionTypes } from './store/modules/app/action-types'
import { LanguageItem } from './model/appModel'
import { getLocale } from './locales'
import { DeviceType } from './store/modules/app/state'
import { deviceDetect } from '@/utils/deviceDetect'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const store = useStore()
    const methods = reactive({
      getNode(key: number) {
        const menuList = window.document.querySelectorAll('.J_Menu-item li') as any
        for (let i = 0, len = menuList.length; i < len; i++) {
          menuList[i].classList.remove('active')
        }
        const item = menuList[key]
        if (item) {
          item.classList.add('active')
          window.sessionStorage.menu = item.dataset.key
        }
      },
      handleScroll() {
        const hot = window.document.querySelector('.J_Hot') as any
        const explain = window.document.querySelector('.J_Explain') as any
        const advantage = window.document.querySelector('.J_Advantage') as any
        const contact = window.document.querySelector('.J_Contact') as any
        const carousel = window.document.querySelector('.J_Carousel') as any
        const magician = window.document.querySelector('.J_Magician') as any
        const hotRect = hot ? hot.getBoundingClientRect() : {}
        const explainRect = explain ? explain.getBoundingClientRect() : {}
        const advantageRect = advantage ? advantage.getBoundingClientRect() : {}
        const contactRect = contact ? contact.getBoundingClientRect() : {}
        const magicianRect = magician ? magician.getBoundingClientRect() : {}

        if (hot && (hotRect.top <= hotRect.height)) {
          methods.getNode(3)
        }
        if (explain && (explainRect.top <= explainRect.height)) {
          methods.getNode(3)
        }
        if (advantage && (advantageRect.top - 80 <= advantageRect.height)) {
          methods.getNode(2)
        }
        if (contact && (contactRect.top - 100 <= contactRect.height)) {
          methods.getNode(1)
        }
        if (carousel && (hotRect.top >= hotRect.height)) {
          methods.getNode(4)
        }
        if (store.state.app.device === 1) {
          if (hot && hotRect.top <= 100) {
            store.dispatch(AppActionTypes.ACTION_SET_LIGHT, true)
          } else {
            store.dispatch(AppActionTypes.ACTION_SET_LIGHT, false)
          }
        } else {
          if (hot && (hotRect.top + 500 <= magicianRect.height)) {
            store.dispatch(AppActionTypes.ACTION_SET_LIGHT, true)
          } else {
            store.dispatch(AppActionTypes.ACTION_SET_LIGHT, false)
          }
        }
      }
    })

    const person = async() => {
      const res: any = await getPerson()
      const config = res?.data ?? {}
      store.dispatch(AppActionTypes.ACTION_SET_CONFIG, config)
    }
    const language = async() => {
      const res: any = await getLanguage()
      const language: {label: string, value: string}[] =
        (res?.data ?? []).map((v: LanguageItem) => ({ label: v.commit, value: v.code }))
      store.dispatch(AppActionTypes.ACTION_SET_LANGUAGE_LIST, language)
    }
    person()
    language()

    onBeforeMount(() => {
      window.document.title = t('home.pageTitle')
      store.dispatch(AppActionTypes.ACTION_TOGGLE_DEVICE, DeviceType[deviceDetect()])
      getLocale()
      const el = window.document.querySelector('html')
      if (el) el.lang = store.state.app.language
    })

    return {
      ...toRefs(methods)
    }
  }
})
