/* eslint-disable */

export default {
  route: {
    home: '首页'
  },
  nav: {
    demoGame: '试玩游戏',
    contactUs: '联系我们',
    ourAdvantage: '我们的优势',
    hotGame: '热门游戏'
  },
  home: {
    pageTitle: '乐游 — 乐趣无限 共赢财富',
    legend: '敬请留下您的联系方式',
    submitSuccess: '提交成功',
    nameTips: '姓名为必填',
    name: '姓名',
    phone: '手机',
    submit: '提交',
    email: '邮箱',
    emailTips1: '邮箱为必填',
    emailTips2: '邮箱格式不正确',
    information: '信息',
    phoneTips: '手机号码格式不正确',
    fgGaming: 'FG乐游（Fungaming）， 亚洲领先的电子游艺博娱供应商，FG以“清”的企业文化打造出FG捕猎、FG棋牌、FG老虎机、FG街机四大类产品线，以多元化的创新思维，累计研发两百多款多元有特色游戏,并以其严谨标准化的研发流程及完善的客户服务运营体系，迅速成长为亚洲市场上顶级游戏供应商。',
    securityTeam: '最核心安全防护能力和一流的专家团队',
    cooperateWithYou: '期待与您一起合作共赢',
    copyright: '版权 © {year} FunGaming 保留所有权利',
    fgAdheres: 'FG秉承乐趣无限,共赢财富的宗旨,为尊贵的客户构建了沟通桥梁,由专业团队协助回答或解决您的疑问和需求',
    shareGame: '与您分享FG的热门力作',
    focusGame: '我们用心专注于游戏,请您品鉴全部游戏作品',
    html5Teconology: '\"HTML5技术\"',
    fgGame: 'FG游戏',
    perfect: '完美',
    compatibel: '兼容',
    device: '各种智能终端设备',
    supportScreen: '支持横竖屏适配及自由切换',
    figure: '\"数值',
    and: '与',
    fluctuation: '波动\"',
    rtpSetting: '合理稳定的RTP数值逻辑设定',
    adjust: '兼顾',
    fluctuations: '游戏波动性',
    achieve: '达到',
    balance: '最佳平衡',
    experience: '\"极致游戏体验\"',
    perfectSound: '动效和音效的完美结合',
    multiNode: '多节点',
    deployment: '部署',
    for: '实现',
    optimal: '最优',
    performance: '性能与',
    extreme: '极致',
    expery: '体验',
    customizedService: '\"定制化服务\"',
    playerPreference: '根据玩家喜好或客户需求',
    provide: '提供',
    multiCurrency: '语言、币种、多开、钱包等',
    customized: '定制服务',
    access: '\"接入',
    accessAnd: '及',
    service: '服务\"',
    flexibleAPI: '灵活简易的API集成接入',
    flexibleProvide: '提供',
    provide7x24: '7×24无间断',
    customerService: '客服咨询及',
    technicalSupport: '技术支持',
    data: '\"数据',
    dataAnd: '和',
    risk: '风控\"',
    professional: '专业的数据分析',
    improves: '提升',
    efficiency: '运营效率',
    multiDimensional: '多维度的风控确保游戏',
    safe: '安全',
    operation: '运营',
    certify: 'Fungaming游戏随机数生成(RNG)经过国际权威机构iTech Labs认证，保证游戏内的随机性。',
    viewMore: '点击查看更多信息'
  },
  hotDetail: {
    rate: '分辨率',
    supportLanguage: '支持语言',
    browser: '浏览器',
    platform: '平台',
    line: '线数',
    realeaseTime: '发布日期',
    gameType: '游戏类别',
    gameName: '游戏名称',
    feature: '游戏特色'
  },
  list: {
    more: '更多'
  }
}
