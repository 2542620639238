import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2f1e958"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-wrapper J_Wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppMain = _resolveComponent("AppMain")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppHeader),
    _createVNode(_component_AppMain),
    _createVNode(_component_AppFooter)
  ]))
}