/*
 * @Description: app Mutations
 */
import { MutationTree } from 'vuex'
import { AppState, Config, DeviceType } from './state'
import { AppMutationTypes } from './mutation-types'
import { setLanguage } from '@/utils/cookies'
import storage from '@/utils/storage'
import Keys from '@/constant/key'

export type Mutations<S = AppState> = {
  [AppMutationTypes.TOGGLE_DEVICE](state: S, device: DeviceType): void
  [AppMutationTypes.SET_LANGUAGE](state: S, language: string): void
  [AppMutationTypes.SET_CONFIG](state: S, config: Config): void
  [AppMutationTypes.SET_LANGUAGE_LIST](state: S, lang: object[]): void
  [AppMutationTypes.SET_LIGHT](state: S, light: boolean): void
}

export const mutations: MutationTree<AppState> & Mutations = {
  [AppMutationTypes.TOGGLE_DEVICE](state: AppState, device: DeviceType) {
    state.device = device
  },

  [AppMutationTypes.SET_LANGUAGE](state: AppState, language: string) {
    state.language = language
    setLanguage(state.language)
    storage.rcSetItem(1, Keys.languageKey, language)
  },

  [AppMutationTypes.SET_CONFIG](state: AppState, config: Config) {
    state.config = config
  },

  [AppMutationTypes.SET_LANGUAGE_LIST](state: AppState, lang: object[]) {
    state.languageList = lang
  },

  [AppMutationTypes.SET_LIGHT](state: AppState, light: boolean) {
    state.light = light
  }
}
