/*
 * @Description: axios 封装网络请求
 */

import HttpClient, { HttpClientConfig } from 'axios-mapper'
import networkConfig from '@/config/default/net.config'
import { ElMessage } from 'element-plus'

const message = (status: number) => {
  let message = ''
  switch (status) {
    case 400:
      message = '请求错误(400)'
      break
    case 401:
      message = '未授权，请重新登录(401)'
      break
    case 403:
      message = '拒绝访问(403)'
      break
    case 404:
      message = '请求出错(404)'
      break
    case 408:
      message = '请求超时(408)'
      break
    case 500:
      message = '服务器错误(500)'
      break
    case 501:
      message = '服务未实现(501)'
      break
    case 502:
      message = '网络错误(502)'
      break
    case 503:
      message = '服务不可用(503)'
      break
    case 504:
      message = '网络超时(504)'
      break
    case 505:
      message = 'HTTP版本不受支持(505)'
      break
    default:
      message = `连接出错(${status})!`
  }
  return `${message}，请检查网络或联系管理员！`
}
const requester = (configParams?: { baseURL: string, headers: object }) => {
  const config: HttpClientConfig = {
    baseURL: configParams?.baseURL || networkConfig.host,
    headers: configParams?.headers || {}
  }
  const https = new HttpClient(config)

  https.httpClient.interceptors.request.use((config) => {
    return config
  }, (error) => {
    ElMessage.error('服务器异常，请联系管理员')
    return Promise.reject(error)
  })
  https.httpClient.interceptors.response.use((response) => {
    const status = response.status
    if (status < 200 || status >= 300) {
      ElMessage.error(message(status))
    } else {
      if (response.data.code !== 0 && response.data.code !== 1001) ElMessage.error(`${response.data.msg}(${response.data.code})`)
    }
    return response
  }, (error) => {
    ElMessage.error('请求失败')
    error.response.msg = '请求失败'
    return Promise.reject(error)
  })

  return https
}

export default requester
