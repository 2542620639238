/*
 * @Description: app actions type
 */

export enum AppActionTypes {
  ACTION_TOGGLE_DEVICE = 'ACTION_TOGGLE_DEVICE',
  ACTION_SET_LANGUAGE = 'ACTION_SET_LANGUAGE',
  ACTION_SET_CONFIG = 'ACTION_SET_CONFIG',
  ACTION_SET_LANGUAGE_LIST = 'ACTION_SET_LANGUAGE_LIST',
  ACTION_SET_LIGHT = 'ACTION_SET_LIGHT'
}
