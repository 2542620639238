/*
 * @Description: app actions
 */
import { ActionTree, ActionContext } from 'vuex'

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store'
import { AppState, Config, DeviceType } from './state'
import { Mutations } from './mutations'
import { AppMutationTypes } from './mutation-types'
import { AppActionTypes } from './action-types'
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<AppState, RootState>, 'commit'>

export interface Actions {
  [AppActionTypes.ACTION_TOGGLE_DEVICE](
    { commit }: AugmentedActionContext,
    device: DeviceType
  ): void
  [AppActionTypes.ACTION_SET_LANGUAGE](
    { commit }: AugmentedActionContext,
    language: string
  ): void
  [AppActionTypes.ACTION_SET_CONFIG](
    { commit }: AugmentedActionContext,
    config: Config
  ): void
  [AppActionTypes.ACTION_SET_LANGUAGE_LIST](
    { commit }: AugmentedActionContext,
    config: object[]
  ): void
  [AppActionTypes.ACTION_SET_LIGHT](
    { commit }: AugmentedActionContext,
    light: boolean
  ): void
}

export const actions: ActionTree<AppState, RootState> & Actions = {
  [AppActionTypes.ACTION_TOGGLE_DEVICE]({ commit }, device: DeviceType) {
    commit(AppMutationTypes.TOGGLE_DEVICE, device)
  },
  [AppActionTypes.ACTION_SET_LANGUAGE]({ commit }, language: string) {
    commit(AppMutationTypes.SET_LANGUAGE, language)
  },
  [AppActionTypes.ACTION_SET_CONFIG]({ commit }, config: Config) {
    commit(AppMutationTypes.SET_CONFIG, config)
  },
  [AppActionTypes.ACTION_SET_LANGUAGE_LIST]({ commit }, lang: object[]) {
    commit(AppMutationTypes.SET_LANGUAGE_LIST, lang)
  },
  [AppActionTypes.ACTION_SET_LIGHT]({ commit }, light: boolean) {
    commit(AppMutationTypes.SET_LIGHT, light)
  }
}
