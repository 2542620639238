import { createI18n } from 'vue-i18n' // import from runtime only

import elementEnLocale from 'element-plus/lib/locale/lang/en'
import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'
import elementThLocale from 'element-plus/lib/locale/lang/th'
import elementViLocale from 'element-plus/lib/locale/lang/vi'

import storage from '@/utils/storage'
import Keys from '@/constant/key'

// User defined lang
import enLocale from './en-us'
import zhLocale from './zh-cn'
import thLocale from './th-th'
import viLocale from './vi-vn'

const messages = {
  'en-us': {
    ...enLocale,
    ...elementEnLocale
  },
  'zh-cn': {
    ...zhLocale,
    ...elementZhLocale
  },
  'th-th': {
    ...thLocale,
    ...elementThLocale
  },
  'vi-vn': {
    ...viLocale,
    ...elementViLocale
  }
}

export const getLocale = () => {
  const lang = storage.rcGetItem(1, Keys.languageKey)
  const localLanguage = lang ? JSON.parse(lang) : ''
  if (localLanguage) {
    return localLanguage
  }
  let language = navigator.language.toLowerCase()
  if (navigator.language.toLowerCase().startsWith('zh-')) language = 'zh-cn'
  else if (navigator.language.toLowerCase() === 'th-th') language = 'th-th'
  else if (navigator.language.toLowerCase() === 'vi-vn') language = 'vi-vn'
  else language = 'en-us'
  const locales = Object.keys(messages)

  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }

  // Default language is english
  return 'en-us'
}

const i18n = createI18n({
  locale: getLocale(),
  messages: messages
})

export default i18n
