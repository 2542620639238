/* eslint-disable */

export default {
  route: {
    home: 'หน้าแรก'
  },
  nav: {
    demoGame: 'ลองเล่นเกม',
    contactUs: 'ติดต่อเรา',
    ourAdvantage: 'ข้อได้เปรียบ',
    hotGame: 'เกมยอดนิยม'
  },
  home: {
    pageTitle: 'Leyou ความสนุกไม่ จำกัด และความมั่งคั่งแบบ win-win',
    legend: 'กรุณาป้อนข้อมูลการติดต่อของคุณ',
    submitSuccess: 'ส่งเรียบร้อยแล้ว',
    nameTips: 'ชื่อที่จำเป็น',
    name: 'ชื่อ',
    phone: 'โทรศัพท์',
    submit: 'ส่ง',
    email: 'จดหมาย',
    emailTips1: 'จำเป็นต้องใช้อีเมล',
    emailTips2: 'รูปแบบอีเมลไม่ถูกต้อง',
    information: 'ข้อความ',
    phoneTips: 'รูปแบบหมายเลขโทรศัพท์มือถือไม่ถูกต้อง',
    fgGaming: 'FG(Fungaming) ซัพพลายเออร์ชั้นนำด้านความบันเทิงอิเล็กทรอนิกส์และเกมแห่งเอเชีย ได้สร้างกลุ่มผลิตภัณฑ์สี่กลุ่ม ได้แก่ FG นักล่า, FG เกมไพ้, FG สล็อตและ FG อาร์เคดด้วยวัฒนธรรมองค์กรที่ "ชัดเจน" ด้วยความคิดเชิงนวัตกรรมที่หลากหลาย FG ได้พัฒนาโมเดลมากกว่า 200 แบบ ทั้งหมด เกมที่หลากหลายและไม่เหมือนใครและด้วยกระบวนการ R&D ที่เข้มงวดและเป็นมาตรฐานและระบบการบริการลูกค้าที่สมบูรณ์แบบทำให้เติบโตอย่างรวดเร็วจนกลายเป็นซัพพลายเออร์เกมชั้นนำในตลาดเอเชีย',
    securityTeam: 'ผสานรวมความสามารถในการป้องกันความปลอดภัยหลักและทีมผู้เชี่ยวชาญชั้นหนึ่ง',
    cooperateWithYou: 'รอคอยที่จะร่วมมือกับคุณสำหรับสถานการณ์ win-win',
    copyright: 'ลิขสิทธิ์ © {year} FunGaming สงวนลิขสิทธิ์',
    fgAdheres: 'FG ยึดมั่นในจุดประสงค์ของความสนุกไร้ขีดจำกัดและความมั่งคั่งแบบ win-win และสร้างสะพานสื่อสารสำหรับลูกค้าที่มีค่า และได้รับความช่วยเหลือจากทีมงานมืออาชีพในการตอบหรือแก้ไขคำถามและความต้องการของคุณ',
    shareGame: 'แบ่งปันผลงานชิ้นเอกยอดนิยมของ FG กับคุณ',
    focusGame: 'เราตั้งใจทำเกม เชิญชวนคุณมาท้าลองทุกผลงาน',
    html5Teconology: '\"เทคโนโลยี HTML5\"',
    fgGame: 'เกม FG',
    perfect: 'สมบูรณ์แบบ',
    compatibel: 'เข้ากันได้',
    device: 'อุปกรณ์ปลายทางอัจฉริยะต่างๆ',
    supportScreen: 'รองรับการปรับหน้าจอแนวนอนและแนวตั้งและการสลับ',
    figure: '\"ค่าตัวเลข',
    and: 'และ',
    fluctuation: 'ความผันผวน\"',
    rtpSetting: 'การตั้งค่า RTP ที่สมเหตุสมผลและเสถียร',
    adjust: 'คำนึงถึง',
    fluctuations: 'ความผันผวนของเกม',
    achieve: 'บรรลุ',
    balance: 'ความสมดุลที่ดีที่สุด',
    experience: '\"ที่สุดของประสบการณ์การเล่นเกม\"',
    perfectSound: 'การผสมผสานที่ลงตัวของการเคลื่อนไหวและเสียงประกอบ',
    multiNode: 'หลายโหนด',
    deployment: 'ปรับใช้',
    for: 'ทำให้สำเร็จ',
    optimal: 'เหมาะสมที่สุด',
    performance: 'ประสิทธิภาพและ',
    extreme: 'สุดขีด',
    expery: 'ประสบการณ์',
    customizedService: '\"บริการที่กำหนดเอง\"',
    playerPreference: 'ตามความต้องการของผู้เล่นหรือความต้องการของลูกค้า',
    provide: 'จัดหาt',
    multiCurrency: 'ภาษา สกุลเงิน การเปิดหลายช่อง กระเป๋าเงิน ฯลฯ',
    customized: 'บริการที่กำหนดเอง',
    access: '\"เข้าถึง',
    accessAnd: 'และ',
    service: 'ให้บริการ\"',
    flexibleAPI: 'การเข้าถึงการรวม API ที่ยืดหยุ่นและง่ายดาย',
    flexibleProvide: 'ให้',
    provide7x24: '7×24 อย่างต่อเนื่อง',
    customerService: 'ที่ปรึกษาลูกค้าและ',
    technicalSupport: 'เทคโนโลยีเทคโนโลยี',
    data: '\"ข้อมูล',
    dataAnd: 'และ',
    risk: 'การควบคุมลม\"',
    professional: 'การวิเคราะห์ข้อมูลอย่างมืออาชีพ',
    improves: 'ส่งเสริม',
    efficiency: 'ประสิทธิภาพการดำเนินงาน',
    multiDimensional: 'การควบคุมความเสี่ยงแบบหลายมิติช่วยให้เกม',
    safe: 'ปลอดภัยและ',
    operation: 'การดำเนินงาน',
    certify: 'เกม RNG ของ Fungaming ได้รับการรับรองโดย iTech Labs เพื่อรับประกันการสุ่มในเกม. ',
    viewMore: 'คลิกเพื่อดูเพิ่มเติม'
  },
  hotDetail: {
    rate: 'ปณิธาน',
    supportLanguage: 'ภาษาที่รองรับ',
    browser: 'เบราว์เซอร์',
    platform: 'แพลตฟอร์ม',
    line: 'ไลน์',
    realeaseTime: 'วันออนไลน์',
    gameType: 'หมวดหมู่เกม',
    gameName: 'ชื่อเกม',
    feature: 'คุณสมบัติของเกม'
  },
  list: {
    more: 'มากกว่า'
  }
}
