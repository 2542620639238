export enum ContentType{
    FORM = 'application/x-www-form-urlencoded',
    JSON = 'application/json; charset=utf-8'
}

export enum Device{
    IOS = 'iOS',
    ANDROID = 'Android',
    WINDOWS = 'Windows',
    PC = 'PC'
}
